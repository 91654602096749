// 首页相关接口
import { api } from '@/utils/axios'
import { UserInfo } from '@/types/apis/user'

interface SaveParams {
  realName: string, //真实姓名
  identity: string, //身份证号
  age: string, //年龄
  gender: string, //性别，1-男、2-女
  workCity: string, //工作城市
  workCityCode: string, //工作城市地区编码
  educationLevel: string, //学历，1-高中及以下、2-专科、3-本科、4-硕士及以上
  houseProperty: string, //房产，0-无房产、1-经济适用房、2-商品房、3-自建房
  carProperty: string, //车产，0-无车产、1-全款车、2-按揭车
  socialSecurity: string, //社保缴纳，0-无社保、1-缴纳6个月以上、2-缴纳未满6个月、
  housingFund: string, //公积金，0-无社保、1-缴纳6个月以上、2-缴纳未满6个月
  insurance: string, //保险保单，0-无保单、1-缴纳不足1年、2-缴纳1年以上
  job: string, //职业身份，1-上班族、1-自由职业者、2-企业主
  licenseDuration: string, //企业年限，1-一年以下、2-一到三年、3-三年以上
  workDuration: string, //工作时间，1-六个月以下、2-六个月到一年、3-一年以上
  payoffForm: string, //工资发放，1-银行转账、2-银行代发、3-现金发送
  monthlyProfit: string, //月收入，1-5000以下、2-5001~10000、3-10001~15000、4-15001~20000、5-20001~30000、6-30000以上
  creditInvestigation: string, //征信情况，1-信用良好，无逾期、2-一年内逾期至多3次、3-一年内逾期大于3次
  creditCard: string, //信用卡，0-无信用卡、1-额度1万以下、2-额度1万~3万、3-额度3万以上
  sesameCredit: string, //芝麻信用，0-无芝麻分、1-650分以下、2-650~700分、3-700分以上
  applyLimit: string //申请额度，1-0万~3万、2-3万~5万、3-5万~10万、4-10万~20万、5-20万~50万、5-50万以上
}
interface FaceVerifyResponse{
  certifyId:string, // 实人认证唯一标识
  certifyUrl: string // Web认证URL
}

interface TempSaveParams{
  realName?: string, //真实姓名
  identity?: string, //身份证号
  age?: string | number, // 年龄
  gender?: string, // 性别
  workCity?: string, //工作城市
  workCityCode?: string, //工作城市地区编码
  sesameCredit?: string, //芝麻信用，0-无芝麻分、1-650分以下、2-650~700分、3-700分以上
  applyLimit?: string //申请额度，1-0万~3万、2-3万~5万、3-5万~10万、4-10万~20万、5-20万~50万、5-50万以上
}

interface ApiResponse<T> {
  data: T;
}

interface LoginInfo {
  uid: string,
  realName: string,
  mobile: string,
  loginAt:string
}

const indexApi = {
  // 保存客户申请信息
  saveApply(body: SaveParams) {
    return api.post('/api/user/info/save', body, {
      // mock: true
    })
  },

  // 暂存客户信息
  tempSave(body: TempSaveParams) {
    return api.post('/api/user/info/tempSave', body, {
      // mock: true
    })
  },

  // 二要素校验(身份证、姓名)
  identityNameVerify(body: any) {
    return api.post('/api/user/info/identityNameVerify', body, {
      // mock: true
    })
  },

  // 保存客户信息
  saveCustomerInfo(body: TempSaveParams) {
    return api.post('/api/user/info/save', body, {
      // mock: true
    })
  },

  // 发起人脸身份识别
  faceVerify(body = {}) {
    return api.post<FaceVerifyResponse>('/api/user/info/faceVerify', body, {
      // mock: true
    })
  },

  // 获取用户资料
  getApplyStatus(body = {}) {
    return api.get<UserInfo>('/api/user/info/detail', body, {
      // mock: true,
      showError: false,
      withoutCheck: true
    })
  },

  // 获取当前登录用户信息
  getLoginUserInfo(body = {}) {
    return api.get<UserInfo>('/api/getLoginUserInfo', body, {
      // mock: true,
      withoutCheck: true
    })
  },

  // 上报bug
  reportBugsnag(message: string) {
    return api.post('https://open.feishu.cn/open-apis/bot/v2/hook/32042512-2f7e-4137-9468-8146d90fb461', {
      // eslint-disable-next-line camelcase
      msg_type: 'text',
      content: { text: `yuanxin-h5 有bug上报到bugsnag了,\n${message}` }
    }, {
      withoutCheck: true
    })
  },

  // 获取用户表单流程中途推出后的信息获取
  getFomrSubmitStatus(body = {}) {
    return api.get<UserInfo>('/api/user/info/status/result', body, {
      // mock: true,
    })
  },

  // 认证接口
  confirmAuthorize(body = {}) {
    return api.put<UserInfo>('/api/user/info/auth', body, {
      // mock: true,
    })
  },

  // 获取需要隐藏的表单项
  getFormItemShow() {
    return api.get('/api/user/info/form/show', null, {
      // mock: true,
    })
  },

  // 获取协议详情
  getAgreementInfo(id:string, productId?: string) {
    return api.get(`/api/user/info/protocol/${id}?productId=${productId ? productId : ''}`, null, {
      // mock: true,
    })
  },

  // 获取用户签署协议的记录
  getAgreementLogInfo(ids:any) {
    return api.get(`/api/user/info/log?${ids}`, null, {
      // mock: true,
    })
  },

  // 上游渠道联登处理 - 检查手机号是否登录
  checkMobileLogin(body:any) {
    return api.post('/api/upStreamUnionLogin/checkMobileLogin', body, {
      // mock: true,
    })
  },

  // 统计表单用户行为
  reportUserBehavior(type:any) {
    return api.post<UserInfo>(`/api/report/type?type=${type}`, null, {
      // mock: true,
      isCancelRequest: false
    })
  },

  // 统计匹配结果页产品列表项的用户点击
  reportUserProductClick(type:any) {
    return api.post<UserInfo>(`/api/report/click?type=${type}`, null, {
      // mock: true,
    })
  },

  // 上报H5手机设备(安卓-IOS)
  reportPhoneType(devicePlatForm:any) {
    return api.put(`/api/report/h5device?devicePlatForm=${devicePlatForm}`, null, {
      // mock: true,
    })
  },

  // 首页列表
  homeProductList(body:any) {
    return api.get('/api/product/columnRecommendProductList', body, {
      // mock: true,
    })
  },
  // 发起产品申请
  applyFor(body = {}) {
    return api.post('/api/product/apply', body, {
      // mock: true,
    })
  },
  // 顶部推荐banner
  topRecommend() {
    return api.get('/api/product/topRecommend', null, {
      // mock: true,
    })
  },
  // 上报产品曝光（不鉴权）
  reportProductExposure(body = {}) {
    return api.get('/api/reportProductExposure', body, {
      // mock: true,
    })
  },

  // CRM账号认证
  authCrmAccount(body = {}) {
    return api.put<UserInfo>('/api/admin/third/wechat/bindOpenid', body, {
      // mock: true,
    })
  },

  // 第三方H5产品联登授权
  authH5Product(body = {}) {
    return api.get<any>('/api/product/h5product/union', body, {
      // mock: true,
    })
  },

  // 账号注销验证码
  logOutVerifyCode(body = {}) {
    return api.post('/api/cancellation/sendPhoneVerifyCode', body, {
      // mock: true,
    })
  },

  // 账号注销
  cancellation(body = {}) {
    return api.post<any>('/api/cancellation', body, {
      // mock: true,
    })
  },

  // 退出登录
  logout(body = {}) {
    return api.post<any>('/api/logout', body, {
      // mock: true,
    })
  },

  // 事件上报接口
  reportEventData(body:any) {
    return api.post<UserInfo>('/api/report/event', body, {
      // mock: true,
      isCancelRequest: false
    })
  },
  // 根据code获取页面配置
  getPageConfigByCode(body:any) {
    return api.get<any>('/api/pageConfig/getPageConfigByCode', body, {
      // mock: true,
    })
  },
  // 统计api 转 H5类型产品跳转
  apiH5JumpUrlSuccessFlag(body:any) {
    return api.post<any>('/api/product/apiH5JumpUrlSuccessFlag', body, {
      // mock: true,
      isCancelRequest: false
    })
  },
  // 获取app首页icon列表
  getAppIndexIconList() {
    return api.get<any>('/api/pageConfig/getAppIndexIconList', null, {
      // mock: true,
    })
  },
  // 首页弹窗
  getHomeDialogBanner(body:any) {
    return api.get<any>('/api/banner/homeDialogBanner', body, {
      // mock: true,
    })
  },
  // 前端日志上报
  reportLog(reportParam:any) {
    return api.post<UserInfo>(`/api/report/log?content=${reportParam}`, null, {
      // mock: true,
      isCancelRequest: false
    })
  },
  // 获取上游协议详情
  getProtocolContent(protocolId:string, productId?: string, maskName?: string, maskMobile?: string, workCity?: string) {
    return api.get(`/api/user/info/protocol2/getContent?protocolId=${protocolId}&productId=${productId}&maskName=${maskName}&maskMobile=${maskMobile}&workCity=${workCity}`, null, {
      // mock: true,
    })
  },

  // 获取登录日志
  getInformationProtocolById(uid:string) {
    return api.get<LoginInfo>(`/api/getUserLoginVO?uid=${uid}`, null, {
      // mock: true,
    })
  },
}
export default indexApi