// 联系内容
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import styles from './contact.module.scss'

interface Props {}

const Contact: React.FC<Props> = () => {
  const [hostname, setHostname] = useState('')

  useEffect(() => {
    setHostname(window.location.hostname)
  }, [])


  return (
    <div className={styles.contact}>
      <div className={classNames(styles.main)}>
        <div className={classNames(styles.title, styles.ignoreConvert)}>
          {hostname === 'h5.yuanxinwallet.com' ? '元信花' : '有钱来'} APP
        </div>
        <div className={styles.ignoreConvert}>如有任何疑问，您可以通过以下方式联系我们</div>
        <div className={styles.ignoreConvert}>
          email： {hostname === 'h5.yuanxinwallet.com' ? '1911818249@qq.com' : '1620827560@qq.com'}
        </div>
        <div className={styles.ignoreConvert}>
          客服电话：{hostname === 'h5.yuanxinwallet.com' ? '13316914762' : '13570420886'}
        </div>
      </div>
    </div>
  )
}

export default Contact
