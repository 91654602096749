/* eslint-disable no-magic-numbers */
// IOS结果页
import React, { useState, useEffect } from 'react'
import styles from './fenqile.module.scss'
import classNames from 'classnames'
interface Props {
  productName?: '', // 产品名字
  applyStatus?: number, // hold单状态
  isMultiProduct:boolean // 判断是否为多产品
  isSkin12?: boolean
}


const IosSkin: React.FC<Props> = props => {
  const { productName, applyStatus, isMultiProduct, isSkin12 } = props
  const [isMjy, setIsMjy] = useState(false)
  let mjyCode = ['PD4Amz', '25WbiH']

  useEffect(() => {
    if (mjyCode.includes(localStorage.getItem('ad_channel_code')!)) {
      setIsMjy(true)
    }
  }, [])
  return (
    <div className={`${styles.iosSkin} ${isSkin12 ? styles.skin12 : ''}`}>
      <div className={styles.main}>
        {
          isMjy ? <></> : <div className={styles.logoBox}>
            <img className={styles.logo} src={isSkin12 ? require('@imgs/register/purple-theme/logo.png') : require('@imgs/youqianqianbao-logo.png')} alt="logo" />
            <div className={styles.logoBoxRight}>
              <img className={styles.youqianLogoText} src={require('@imgs/youqianqianbao-text.png')} alt="youqian-text" />
              <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle.png')} alt="正规品牌 真实可信" />
            </div>
          </div>
        }
        {
          productName ? <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              {
                applyStatus === 8 ? <>
                  <div className={styles.applyResultBoxRight}>
                    <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.applyPrductionName}>{!isMultiProduct && productName}</span>匹配成功！</div>
                    <div className={styles.applyResultBoxText2}>审核人员将在<span className={styles.highlight}>工作时间来电</span>完成审核</div>
                  </div>
                </> : <>
                  <div className={styles.applyResultBoxRight}>
                    <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.applyPrductionName}>{!isMultiProduct && productName}</span>申请成功！</div>
                    <div className={styles.applyResultBoxText2}>请留意审核人员来电，完成信息审核</div>
                  </div>
                </>
              }
            </div>
            <div className={classNames(styles.containers, styles.passBox)}>
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                <div className={styles.containerItemSuccessText}>预审通过</div>
              </div>
              <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                <div className={styles.containerItemFailText}>请留意审核人员来电</div>
              </div>
            </div>
          </> : <>
            <div className={classNames(styles.containers, styles.failBox)}>
              <img className={styles.noPass} src={require('@imgs/form-result/no-pass.png')} alt="no-pass" />
              <div className={styles.failBoxText1}>预审未通过</div>
              <div className={styles.failBoxText2}>抱歉，您的预审未通过。</div>
              <div className={styles.failBoxText3}>请点击左上角进行退出</div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default IosSkin
