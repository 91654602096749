import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import styles from './choose-option.module.scss'
import { OptionType } from '../../options'
interface Props{

    /** 初始化是否展开 */
    initIsExpansion?: boolean,

    /** 是否支持展开 */
    isSupportExpansion?: boolean,

    /** 是否支持多选 */
    isSupportMultiple?: boolean,

    /** 选中的值 */
    value?: any,

    /** 改变事件 */
    onChange: (obj:OptionType) => void,

    /** 名称 */
    name: string,

    /** 副名称 */
    subName?: string,

    /** 选项数组 */
    options: OptionType[]

    /** 宽度 */
    width?: string

    /** 换行数字 */
    warpNum?: number,

    // 处理边框显示
    firstShowBottom?: boolean

    isSkin12?:boolean
    /**  */
}
// 选择选项
const ChooseOption:React.FC<Props> = props => {
  const {
    initIsExpansion = true,
    isSupportExpansion = true,
    isSupportMultiple = false,
    value,
    name,
    subName,
    options,
    onChange,
    width,
    firstShowBottom,
    // eslint-disable-next-line no-magic-numbers
    warpNum = 3,
    isSkin12
  } = props

  const [label, setLabel] = useState<string>()
  const [labelList, setLabelList] = useState<string[]>([])
  const [multipleValues, setMultipleValues] = useState<string[]>([])
  const [firstLoad, setFirstLoad] = useState(true)

  // 根据传入的值回显
  useEffect(() => {
    if (value || value === '0') {
      if (isSupportMultiple && firstLoad) {
        setFirstLoad(false)
        let lebelArr:any = []
        let valueArr:any = []
        value.map((valueItem:any) => {
          const temp = options.find(item => item.value == valueItem)
          lebelArr.push(temp?.label)
          valueArr.push(temp?.value)
          return temp
        })
        setLabelList([...lebelArr])
        setMultipleValues([...valueArr])
      } else {
        const temp = options.find(item => item.value === value)
        if (temp) {
          setLabel(temp.label)
        }
      }
    }
  }, [value, options, isSupportMultiple])

  // 是否展开
  const [isExpansion, setIsExpansion] = useState(false)

  const toggle = () => {
    setIsExpansion(!isExpansion)
  }

  useEffect(() => {
    setIsExpansion(initIsExpansion)
  }, [initIsExpansion])

  // 展开高度
  const expansionHeight = useMemo(() => {
    if (options.length) {
      // eslint-disable-next-line no-magic-numbers
      return `${Math.ceil(options.length / warpNum) * 11.7333}vw`
    }

    return 0
  }, [options, warpNum])

  // 点击选项
  const selectOption = (item:OptionType) => {
    setLabel(item.label)
    onChange(item)
    isSupportExpansion && toggle()
  }

  // 点击选项-多选
  const selectMultipleOption = (item:OptionType) => {
    const haveLabelIndex = labelList.indexOf(item.label as never)
    if (haveLabelIndex !== -1) {
      // 删除值
      labelList.splice(haveLabelIndex, 1)
      multipleValues.splice(haveLabelIndex, 1)
    } else {
      // 保存值
      // 保存多选的label字段
      labelList.push(item.label as never)
      // 保存多选的值
      multipleValues.push(item.value as never)
    }
    setLabelList([...labelList])
    setMultipleValues([...multipleValues])
    onChange(multipleValues as never)
    // isSupportExpansion && toggle()
  }

  return (
    <div className={classNames(styles.chooseOption, firstShowBottom && styles.borderBottom, isSkin12 && styles.isSkin12)}>
      <div className={classNames(styles.top)} onClick={toggle}>
        <div>{name}<span className={styles.subName}>{subName}</span></div>
        {
          isSupportExpansion && <div className={styles.right}>
            <span className={classNames(!label && styles.placeholder)}>{label ? label : `请选择${name}` }</span>
            <i className={classNames(styles.arrow, isExpansion && styles.rotate)}></i>
          </div>
        }
      </div>
      {
        isSupportMultiple ? <> <div className={styles.optionBox} style={{ height: isExpansion ? expansionHeight : '0px' }}>
          {options.map(item => {
            return <div
              key={item.value}
              className={classNames(styles.item, (labelList.indexOf(item.label as never) !== -1) && styles.active)}
              onClick={() => selectMultipleOption(item)}
              style={{ width: width || '' }}
            >
              {item.label}
            </div>
          })}
        </div>
        </> : <>
          <div className={styles.optionBox} style={{ height: isExpansion ? expansionHeight : '0px' }}>
            {options.map(item => {
              return <div
                key={item.value}
                className={classNames(styles.item, item.label === label && styles.active)}
                onClick={() => selectOption(item)}
                style={{ width: width || '' }}
              >
                {item.label}
              </div>
            })}
          </div>
        </>
      }
    </div>
  )
}
export default ChooseOption