import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import styles from './form-choose-item.module.scss'
import { OptionType } from '../../options'
interface Props{

    /** 初始化是否展开 */
    initIsExpansion?: boolean,

    /** 是否支持展开 */
    isSupportExpansion?: boolean,

    /** 是否支持多选 */
    isSupportMultiple?: boolean,

    /** 选中的值 */
    value?: any,

    /** 改变事件 */
    onChange: (obj:OptionType) => void,

    /** 名称 */
    name: string,

    /** 选项数组 */
    options: OptionType[]

    /** 宽度 */
    width?: string

    /** 换行数字 */
    warpNum?: number,

    isSkin12?:boolean
    /**  */
}
// 选择选项
const ChooseOption:React.FC<Props> = props => {
  const {
    initIsExpansion = true,
    isSupportExpansion = true,
    isSupportMultiple = false,
    value,
    name,
    options,
    onChange,
    width,
    // eslint-disable-next-line no-magic-numbers
    warpNum = 3,
    isSkin12
  } = props

  const [label, setLabel] = useState<string>()
  const [labelList, setLabelList] = useState<string[]>([])
  const [multipleValues, setMultipleValues] = useState<string[]>([])
  const [firstLoad, setFirstLoad] = useState(true)

  // 根据传入的值回显
  useEffect(() => {
    if (value || value === '0') {
      const temp = options.find(item => item.value === value)
      if (temp) {
        setLabel(temp.label)
      }
    }
  }, [value, options, isSupportMultiple])

  // 是否展开
  const [isExpansion, setIsExpansion] = useState(false)

  const toggle = () => {
    setIsExpansion(!isExpansion)
  }

  useEffect(() => {
    setIsExpansion(initIsExpansion)
  }, [initIsExpansion])

  // 展开高度
  const expansionHeight = useMemo(() => {
    if (options.length) {
      // eslint-disable-next-line no-magic-numbers
      return `${Math.ceil(options.length / warpNum) * 11.7333}vw`
    }

    return 0
  }, [options, warpNum])

  // 点击选项
  const selectOption = (item:OptionType) => {
    setLabel(item.label)
    onChange(item)
  }

  // 点击选项-多选
  const selectMultipleOption = (item:OptionType) => {
    const haveLabelIndex = labelList.indexOf(item.label as never)
    if (haveLabelIndex !== -1) {
      // 删除值
      labelList.splice(haveLabelIndex, 1)
      multipleValues.splice(haveLabelIndex, 1)
    } else {
      // 保存值
      // 保存多选的label字段
      labelList.push(item.label as never)
      // 保存多选的值
      multipleValues.push(item.value as never)
    }
    setLabelList([...labelList])
    setMultipleValues([...multipleValues])
    onChange(multipleValues as never)
    // isSupportExpansion && toggle()
  }

  return (
    <div className={`${styles.formChooseItem} ${isSkin12 ? styles.skin12 : ''}`}>
      <div className={styles.formChooseItemTitle}>{ name }</div>
      <div className={styles.options}>
        {options.map((item, index) => {
          return <div
            key={index}
            className={classNames(styles.option, item.label === label && styles.active)}
            onClick={() => selectOption(item)}
            style={{ width: width || '' }}
          >
            {item.label}
          </div>
        })}
      </div>
    </div>
  )
}
export default ChooseOption