export interface OptionType{
  label: string,
  value: string,
  num?:number
}

// 获取映射信息
export function getMap(obj:OptionType[]) {
  let list = Object.values(obj)
  let result:Record<string, string> = {}
  list.forEach(item => {
    result[item.value] = item.label
  })
  return Object.freeze(result)
}

// 性别选项
export const sexOption = [
  {
    label: '男',
    value: '1'
  },
  {
    label: '女',
    value: '2'
  },
]

export const sexOptionMap = getMap(sexOption)

// 学历选项
export const educationOptions:OptionType[] = [
  {
    label: '高中及以下',
    value: '1'
  },
  {
    label: '专科',
    value: '2'
  },
  {
    label: '本科',
    value: '3'
  },
  {
    label: '硕士及以上',
    value: '4'
  },
]

export const educationOptionsMap = getMap(educationOptions)

// 房产选项
export const realEstateOptions = [
  {
    label: '有且接受抵押',
    value: '2',
    num: 50000
  },
  {
    label: '有',
    value: '1',
    num: 45000
  },
  {
    label: '无',
    value: '0',
    num: 5000
  }
]
export const realEstateOptionsMap = getMap(realEstateOptions)

// 车产选项
export const carProductionOptions = [
  {
    label: '有且接受抵押',
    value: '2',
    num: 15000
  },
  {
    label: '有',
    value: '1',
    num: 5000
  },
  {
    label: '无',
    value: '0',
    num: 0
  }
]
export const carProductionOptionsMap = getMap(carProductionOptions)

// 社保缴纳选项
export const socialSecurityOptions = [
  {
    label: '6个月以上',
    value: '2',
    num: 7000
  },
  {
    label: '6个月以下',
    value: '1',
    num: 3000
  },
  {
    label: '无社保',
    value: '0',
    num: 0
  },
]
export const socialSecurityOptionsMap = getMap(socialSecurityOptions)

// 公积金选项
export const accumulationFundOptions = [
  {
    label: '6个月以上',
    value: '2',
    num: 7000
  },
  {
    label: '6个月以下',
    value: '1',
    num: 3000
  },
  {
    label: '无公积金',
    value: '0',
    num: 0
  },
]
export const accumulationFundOptionsMap = getMap(accumulationFundOptions)

// 保险保单选项
export const insurancePolicyOptions = [
  {
    label: '缴纳1年以上',
    value: '2',
    num: 7000
  },
  {
    label: '缴纳未满1年',
    value: '1',
    num: 3000
  },
  {
    label: '无',
    value: '0',
    num: 0
  },
]
export const insurancePolicyOptionsMap = getMap(insurancePolicyOptions)

// 职业身份选项
export const professionalIdentityOptions = [
  {
    label: '上班族',
    value: '1',
  },
  {
    label: '自由职业者',
    value: '2',
  },
  {
    label: '企业主',
    value: '3',
  }
]
export const professionalIdentityOptionsMap = getMap(professionalIdentityOptions)


// 企业年限选项
export const enterpriseYearOptions = [
  {
    label: '1年以下',
    value: '1',
  },
  {
    label: '1-3年',
    value: '2',
  },
  {
    label: '3年以上',
    value: '3',
  }
]

export const enterpriseYearOptionsMap = getMap(enterpriseYearOptions)

// 工作时间
export const workTimeOptions = [
  {
    label: '6个月以下',
    value: '1',
  },
  {
    label: '6-12个月',
    value: '2',
  },
  {
    label: '12个月以上',
    value: '3',
  }
]

export const workTimeOptionsMap = getMap(workTimeOptions)

// 工资发放
export const salaryOptions = [
  {
    label: '银行转账',
    value: '1',
    num: 5000
  },
  {
    label: '银行代发',
    value: '2',
    num: 3000
  },
  {
    label: '现金发放',
    value: '3',
    num: 0
  }
]

export const salaryOptionsMap = getMap(salaryOptions)

// 月收入
export const monthlyIncomeOptions = [
  {
    label: '5000元以下',
    value: '1',
  },
  {
    label: '5001-10000',
    value: '2',
  },
  {
    label: '10001-15000',
    value: '3',
  },
  {
    label: '15001-20000',
    value: '4',
  },
  {
    label: '20001-30000',
    value: '5',
  },
  {
    label: '30000以上',
    value: '6',
  }
]
export const monthlyIncomeOptionsMap = getMap(monthlyIncomeOptions)

// 信用情况选项
export const creditConditionsOptions = [
  {
    label: '花呗无逾期',
    value: '10',
  },
  {
    label: '花呗有逾期',
    value: '11',
  },
  {
    label: '白条无逾期',
    value: '20',
  },
  {
    label: '白条有逾期',
    value: '21',
  },
  {
    label: '信用卡无逾期',
    value: '30',
  },
  {
    label: '信用卡有逾期',
    value: '31',
  },
]
export const creditConditionsOptionsMap = getMap(creditConditionsOptions)


// 信用卡额度选项
export const creditCardOptions = [
  {
    label: '无信用卡',
    value: '0',
  },
  {
    label: '1万以下',
    value: '1',
  },
  {
    label: '1万-3万',
    value: '2',
  },
  {
    label: '3万以上',
    value: '3',
  },
]
export const creditCardOptionsMap = getMap(creditCardOptions)

// 芝麻分选项
export const creditSesameOptions = [
  {
    label: '无芝麻分',
    value: '0',
    num: 0
  },
  // {
  //   label: '650分以下',
  //   value: '1',
  // },
  {
    label: '600分以下',
    value: '4',
    num: 1000
  },
  {
    label: '600~650分',
    value: '5',
    num: 2000
  },
  {
    label: '650-700分',
    value: '2',
    num: 4000
  },
  {
    label: '700分以上',
    value: '3',
    num: 8000
  },
]
export const creditSesameOptionsMap = getMap(creditSesameOptions)

// 申请额度
export const applyCreditOptions = [
  {
    label: '10万-20万',
    value: '4',
    num: 16000
  },
  {
    label: '5万-10万',
    value: '3',
    num: 12000
  },
  {
    label: '3万-5万',
    value: '2',
    num: 8000
  },
  {
    label: '0-3万',
    value: '1',
    num: 4000
  },
]
export const applyCreditOptionsMap = getMap(applyCreditOptions)

// -----

// 职业类型
export const jobTypeOptions = [
  {
    label: '上班族',
    value: '10',
    num: 5000
  },
  {
    label: '自由职业者',
    value: '20',
    num: 0
  },
  {
    label: '企业主',
    value: '30',
    num: 20000
  },
]
export const jobTypesOptionsMap = getMap(jobTypeOptions)

// 资产情况
export const assetSituationOptions = [
  {
    label: '有房',
    value: '10',
  },
  {
    label: '有车',
    value: '20',
  },
  {
    label: '有保单',
    value: '30',
  },
  {
    label: '有公积金',
    value: '40',
  },
  {
    label: '有社保',
    value: '50',
  },
]
export const assetSituationOptionsMap = getMap(assetSituationOptions)

// 工作年限
export const workDurationOptions = [
  {
    label: '6个月以下',
    value: '10',
  },
  {
    label: '6-12个月',
    value: '20',
  },
  {
    label: '12个月以上',
    value: '30',
  },
]
export const workDurationOptionsMap = getMap(workDurationOptions)

// 月收入
export const monthlyProfitOptions = [
  {
    label: '5000以下',
    value: '1',
  },
  {
    label: '5000~1万',
    value: '2',
  },
  {
    label: '1万以上',
    value: '3',
  },
]
export const monthlyProfitOptionsMap = getMap(monthlyProfitOptions)

// 工资发放方式
export const payoffFormOptions = [
  {
    label: '银行转账',
    value: '1',
    num: 5000
  },
  {
    label: '银行代发',
    value: '2',
    num: 3000
  },
  {
    label: '现金',
    value: '3',
    num: 0
  },
]
export const payoffFormOptionsMap = getMap(payoffFormOptions)

// 信用情况
export const creditOptions = [
  {
    label: '当前有逾期',
    value: '1',
    num: 0
  },
  {
    label: '无逾期',
    value: '0',
    num: 5000
  },
]