/* eslint-disable no-magic-numbers */
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Button } from 'antd-mobile'
import styles from './360-white-list-city.module.scss'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
interface Props {
  onChange: () => void, // 改变事件
  isSkin12?: boolean
}

const WhiteListCity: React.FC<Props> = props => {
  const { onChange, isSkin12 } = props

  return (
    <div className={`${styles.iosSkin} ${isSkin12 ? styles.skin12 : ''}`}>
      <div className={styles.main}>
        <div className={styles.logoBox}>
          <img className={styles.logo} src={isSkin12 ? require('@imgs/register/purple-theme/logo.png') : require('@imgs/youqianqianbao-logo.png')} alt="logo" />
          <div className={styles.logoBoxRight}>
            <img className={styles.youqianLogoText} src={require('@imgs/youqianqianbao-text.png')} alt="youqian-text" />
            <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle.png')} alt="正规品牌 真实可信" />
          </div>
        </div>
        <div className={styles.applyResultBox}>
          <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
          <div className={styles.applyResultBoxRight}>
            <div className={styles.applyResultBoxText1}>您已注册成功</div>
            <div className={styles.applyResultBoxText2}>请下载APP进行实名认证，获取额度</div>
          </div>
        </div>
        <div className={classNames(styles.containers, styles.passBox)}>
          <img className={styles.containersBg} src={require('@imgs/form-result/bg.png')} alt="bg" />
          <div className={styles.textBox}>
            <div className={styles.textBoxTitle}>最高可借(元)</div>
            <div className={styles.textBoxPrice}>50,000</div>
          </div>
        </div>
        <div className={styles.btnBox}>
          <Button block className={styles.btn} shape="rounded" color="primary" onClick={onChange}>立即下载APP</Button>
        </div>
      </div>
    </div>
  )
}

export default WhiteListCity
