import React, { useState, useImperativeHandle, useEffect } from 'react'
import styles from './protocolIds-confirm-2.module.scss'
import { Button, Toast } from 'antd-mobile'
import indexApi from '@/apis'
import { TOKEN, ENTER_AGREEMENT, CURRENT_SKIN_INDEX } from '@/constants/storage'
import { parseNum, isPhone, Storage } from '@bihu/common-js'
import classNames from 'classnames'
import { useNavigate, useSearchParams } from 'react-router-dom'
import activityApi from '@/apis/activity'

// interface Props{
// }
// 协议确认
export interface Props {
  init?: (obj: any | undefined) => void,
  onSuccess: (obj: any | undefined) => void
  openProtocolsPopup: () => void
  submit: () => void
}
const Success = React.forwardRef(({ init, onSuccess, openProtocolsPopup, submit }: Props, ref: React.ForwardedRef<Props>) => {
  const [isShow, setIsShow] = useState(false)
  const [search] = useSearchParams()

  // 有钱来个人信息授权书
  const [youqianlaiProtocol, setYouqianlaiProtocol] = useState<any>('')
  const [otherProtocols, setotherProtocols] = useState([])
  const [apiChannelCode, setApiChannelCode] = useState(localStorage.getItem('ad_channel_code'))

  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 选择协议
  const [checked, setChecked] = useState(false)
  // 是否抖动
  const [isShake, setIsShake] = useState(false)
  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)
  const [productId, setProductId] = useState<any>('')
  let mjyCode = ['PD4Amz', '25WbiH']

  init = (obj:any | undefined) => {
    setBtnLoading(false)
    if (obj) {
      setYouqianlaiProtocol(obj.youqianlaiProtocol)
      setotherProtocols(obj.otherProtocols)
      setIsShow(true)
      setProductId(obj.productId)
    }
  }

  const close = () => {
    setIsShow(false)
  }

  const checkProtocolsPopup = () => {
    openProtocolsPopup()
  }

  const submitApplication = () => {
    setBtnLoading(true)
    close()
    submit()
  }
  // 暴露内部方法
  useImperativeHandle(ref, () => ({
    init,
    onSuccess,
    openProtocolsPopup,
    submit
  }))

  // 跳转页面
  const openOtherView = (url: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    window.location.href = url
  }

  return (
    <>
      {
        isShow && <section className={styles.modalPage}>
          <div className={styles.main}>
            <div className={styles.modalTitle}>温馨提示</div>
            <div className={styles.modalText1}>我已阅读并同意</div>
            <div className={styles.protocolIds}>
              <div className={styles.protocolIdsItem} onClick={() => {
                openOtherView(`/info-audit-agreement?isShowHeader=true&agreementId=${youqianlaiProtocol.id}&title=${youqianlaiProtocol.name}&productId=${productId}&protocolType=${mjyCode.includes(apiChannelCode!) ? 1 : 0}`)
              }}>《{youqianlaiProtocol.name}》</div>
              {
                otherProtocols.length > 0 && <div className={styles.protocolIdsItem} onClick={() => checkProtocolsPopup()}>《机构相关协议》</div>
              }
            </div>
            <div className={styles.btnsBox}>
              <Button block className={styles.cancelBtn} shape="rounded" color="primary" onClick={() => close()}>
                取消
              </Button>
              <Button block loading={btnLoading} loadingText="请等待机构申请结果" className={styles.btn} shape="rounded" color="primary" onClick={() => submitApplication()}>
                同意并继续
              </Button>
            </div>
          </div>
        </section>
      }
    </>
  )
})

export default Success