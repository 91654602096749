import React, { useState, useImperativeHandle } from 'react'
import styles from './joint-login-popup.module.scss'
import { Button, Popup, Toast } from 'antd-mobile'
import { useAppSelector } from '@/store/hooks'
import { selectUserInfo } from '@/store/user'
import indexApi from '@/apis'
import classNames from 'classnames'

interface Props{
}
// 广告产品联登授权弹窗
export interface ModalRefType {
    init: (params:any) => void
}
const Success = React.forwardRef<ModalRefType, Props>((props: Props, ref) => {

  // 联登产品信息
  const [authProductData, setAuthProductData] = useState<any>({})
  // 用户信息
  const userInfo = useAppSelector(selectUserInfo)
  // 是否显示弹出层
  const [jointLoginVisible, setJointLoginVisible] = useState(false)

  // 选择协议
  const [checked, setChecked] = useState(false)

  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 跳转页面
  const openOtherView = (e:any, url: string) => {
    e.stopPropagation()
    window.location.href = url
  }

  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)
  const handleAuthorize = async(unNeedAgree?:boolean, applyDetails?:any) => {
    let protocolIds:any = []
    if (unNeedAgree) {
      protocolIds = applyDetails.h5Protocols.map((item:any) => {
        return item.id
      })
    } else {
      if (!checked) {
        Toast.show('请勾选下方协议')
        return
      }
      protocolIds = authProductData.h5Protocols.map((item:any) => {
        return item.id
      })
    }
    setBtnLoading(true)
    const res:any = await indexApi.authH5Product({
      productId: unNeedAgree ? applyDetails.productId : authProductData.productId,
      protocolIds: `${protocolIds}${''}`,
      agree: unNeedAgree ? true : checked,
      entranceType: unNeedAgree ? applyDetails.entranceType : authProductData.entranceType,
      appH5: unNeedAgree ? applyDetails.appH5 : authProductData.appH5,
    })
    setBtnLoading(false)
    window.location.href = res
  }

  const init = async(params:any) => {
    if (!params) {
      return
    }
    setAuthProductData(params)
    // 获取联登授权弹窗开关设置 string 1：开 0：关
    const result = await indexApi.getPageConfigByCode({ code: 'H5_PRODUCT_WINDOWS_FLAG' })
    if (result.value === '1') {
      setJointLoginVisible(true)
    } else {
      handleAuthorize(true, params)
    }
  }

  const close = () => {
    setJointLoginVisible(false)
  }
  // 暴露内部方法
  useImperativeHandle(ref, () => ({
    init
  }))

  // 千位分隔符
  const numFormat = (num:number) => {
    if (!num) {
      return
    }
    let res = num.toString().replace(/\d+/, n => { // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, $1 => {
        return `${$1},`
      })
    })
    return res
  }

  return (
    <Popup
      className={styles.jointLoginPopup}
      visible={jointLoginVisible}
      onMaskClick={() => {
        setJointLoginVisible(false)
      }}
    >
      <div className={styles.jointLoginBox}>
        <div className={styles.productBox}>
          <img className={styles.productLogo} src={authProductData.productLogo} alt="img" />
          <span className={styles.productName}>{ authProductData.productName }</span>
        </div>
        <div className={styles.limitBox}>
          <div className={styles.limitBoxNum}>{numFormat(authProductData?.loansLimitMax)}</div>
          <div className={styles.limitBoxText}>最高可借额度(元)</div>
        </div>
        <div className={styles.btnBox}>
          <Button block loading={btnLoading} loadingText="授权中" className={styles.btn} shape="rounded" color="primary" onClick={() => handleAuthorize()}>
            <div className={styles.btnText}>确认授权</div>
            {/* <div className={styles.btnTips}>{`系统将使用手机号 ${userInfo && userInfo.mobile.replace(/(\d{3})(\d{4})(\d{4})/g, '$1****$3')}`}</div> */}
          </Button>
        </div>
        <div className={styles.agreementBox}>
          {
            checked ? <>
              <img onClick={checkedTaggle} className={styles.checkedIcon} src={require('@imgs/information/checked.png')} />
            </> : <>
              <div className={classNames(styles.checked)} onClick={checkedTaggle}></div>
            </>
          }
          <div>
            <span onClick={checkedTaggle}>同意并签署</span>
            {
              authProductData.h5Protocols && authProductData.h5Protocols.map((item:any) => {
                return (
                  <span
                    className={styles.agreementItem}
                    onClick={e => {
                      openOtherView(e, `/info-audit-agreement?isShowHeader=true&agreementId=${item.id}&title=${item.name}&productId=${authProductData.productId}`)
                    }}
                    key={item.id}
                  >
                    { `《${item.name}》` }
                  </span>
                )
              })
            }
          </div>
        </div>
      </div>
    </Popup>
  )
})

export default Success