/* eslint-disable no-magic-numbers */
import React, { useCallback, useState, useEffect, useRef } from 'react'
import styles from './normal-skin.module.scss'
import classNames from 'classnames'
import { Button } from 'antd-mobile'
interface Props {
  productName?: '', // 产品名字
  applyStatus?: number, // hold单状态
  rePageShowType?: number,
  thirdpartyApiType?: number,
  thirdpartyType?: number,
  countdownTime: number,
  onChange: () => void, // 改变事件
  isMultiProduct:boolean,
  isSkin12?: boolean
}

const NormalSkin: React.FC<Props> = props => {
  const { productName, rePageShowType, countdownTime, onChange, isMultiProduct, isSkin12 } = props
  const [isMjy, setIsMjy] = useState(false)
  let mjyCode = ['PD4Amz', '25WbiH']

  useEffect(() => {
    if (mjyCode.includes(localStorage.getItem('ad_channel_code')!)) {
      setIsMjy(true)
    }
  }, [])
  return (
    <div className={`${styles.fullPage} ${isSkin12 ? styles.skin12 : ''}`}>
      <div className={styles.main}>
        {
          isMjy ? <></> : <div className={styles.logoBox}>
            <img className={styles.logo} src={isSkin12 ? require('@imgs/register/purple-theme/logo.png') : require('@imgs/youqianqianbao-logo.png')} alt="logo" />
            <div className={styles.logoBoxRight}>
              <img className={styles.youqianLogoText} src={require('@imgs/youqianqianbao-text.png')} alt="youqian-text" />
              <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle.png')} alt="正规品牌 真实可信" />
            </div>
          </div>
        }
        {
          productName ? <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              <div className={styles.applyResultBoxRight}>
                <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.active}>{!isMultiProduct && productName}</span>申请成功！</div>
                <div className={styles.applyResultBoxText2}>请留意审核人员来电，完成信息审核</div>
              </div>
            </div>
            <div className={classNames(styles.containers)}>
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                <div className={styles.containerItemSuccessText}>预审通过</div>
              </div>
              <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                <div className={styles.containerItemFailText}>请留意审核人员来电</div>
              </div>
            </div>
          </> : <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              <div className={styles.applyResultBoxRight}>
                <div className={styles.applyResultBoxText1}><span className={styles.active}>您已预审通过</span></div>
                <div className={classNames(styles.applyResultBoxText2, styles.smallText)}>抱歉，当前申请名额已满，请下载APP查看</div>
              </div>
            </div>
            <div className={classNames(styles.containers)}>
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                <div className={styles.containerItemSuccessText}>预审通过</div>
              </div>
              <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                <div className={styles.containerItemFailText}>下载APP领取额度</div>
              </div>
            </div>
          </>
        }
      </div>
      {
        rePageShowType !== 2 && rePageShowType !== 1 && <div className={styles.btnBox}>
          <div className={styles.downLoadTips}>快前往APP查看更多可借产品</div>
          <Button block className={styles.btn} shape="rounded" color="primary" onClick={onChange}>{`点击下载APP${countdownTime > 0 ? `（${countdownTime}）` : ''}`}</Button>
        </div>
      }
    </div>
  )
}

export default NormalSkin
