/* eslint-disable no-magic-numbers */
import React, { useCallback, useState, useEffect, useRef } from 'react'
import styles from './mini-program.module.scss'
import classNames from 'classnames'
import { Button } from 'antd-mobile'
interface Props {
  productName?: '', // 产品名字
  applyStatus?: number, // hold单状态
  onChange: () => void, // 改变事件
  isSkin12?: boolean
}

const IosSkin: React.FC<Props> = props => {
  const { productName, applyStatus, isSkin12, onChange } = props

  return (
    <div className={`${styles.fullPage} ${isSkin12 ? styles.skin12 : ''}`}>
      <div className={styles.main}>
        <div className={styles.logoBox}>
          <img className={styles.logo} src={isSkin12 ? require('@imgs/register/purple-theme/logo.png') : require('@imgs/youqianqianbao-logo.png')} alt="logo" />
          <div className={styles.logoBoxRight}>
            <img className={styles.youqianLogoText} src={require('@imgs/youqianqianbao-text.png')} alt="youqian-text" />
            <img className={styles.logoText2} src={require('@imgs/register/new-theme/logo-subtitle.png')} alt="正规品牌 真实可信" />
          </div>
        </div>
        {
          productName ? <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              {
                applyStatus === 8 ? <>
                  <div className={styles.applyResultBoxRight}>
                    <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.active}>{productName}</span>匹配成功！</div>
                    <div className={styles.applyResultBoxText2}>审核人员将在<span className={styles.highlight}>工作时间来电</span>完成审核</div>
                  </div>
                </> : <>
                  <div className={styles.applyResultBoxRight}>
                    <div className={styles.applyResultBoxText1}>恭喜, <span className={styles.active}>{productName}</span>申请成功！</div>
                    <div className={styles.applyResultBoxText2}>请留意审核人员来电，完成信息审核</div>
                  </div>
                </>
              }
            </div>
            <div className={classNames(styles.containers, styles.passBox)}>
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/pass.png')} alt="pass" />
                <div className={styles.containerItemSuccessText}>预审通过</div>
              </div>
              <img className={styles.line} src={require('@imgs/form-result/line.png')} alt="line" />
              <div className={styles.containerItem}>
                <img className={styles.applyResultBoxIcon} src={require('@imgs/form-result/phone.png')} alt="phone" />
                <div className={styles.containerItemFailText}>请留意审核人员来电</div>
              </div>
            </div>
          </> : <>
            <div className={styles.applyResultBox}>
              <img className={styles.applyResultBoxIcon} src={require('@imgs/ios-skin/success.png')} alt="success" />
              <div className={styles.applyResultBoxRight}>
                <div className={styles.applyResultBoxText1}><span className={styles.active}>您已预审通过</span></div>
                <div className={classNames(styles.applyResultBoxText2, styles.smallText)}>抱歉，当前申请名额已满，请下载APP查看</div>
              </div>
            </div>
            <div className={classNames(styles.containers, styles.failBox)}>
              <img className={styles.containersBg} src={require('@imgs/form-result/bg.png')} alt="bg" />
              <div className={styles.textBox}>
                <div className={styles.textBoxTitle}>最高可借(元)</div>
                <div className={styles.textBoxPrice}>50,000</div>
              </div>
            </div>
          </>
        }
      </div>
      <div className={styles.btnBox}>
        <div className={styles.downLoadTips}>快前往APP查看更多可借产品</div>
        <Button block className={styles.btn} shape="rounded" color="primary" onClick={onChange}>点击复制链接到浏览器打开下载APP</Button>
      </div>
    </div>
  )
}

export default IosSkin
